export default {
  data() {
    return {
      swalOptions: {
        'title': 'Sweet Alert Title',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }
    }
  },
  methods : {
    showLoaderRequest(title) {
      swal.fire({...this.swalOptions, ...{title: title}});
      swal.showLoading();
    },
    hideLoaderRequest() {
      swal.close();
    }
  }
}
